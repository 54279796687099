<template>
  <master-layout>

    <ion-card>
      <ion-card-content>
        <img src="assets/images/illustration-login.svg" class="illustration"/>
        <h1>Regisztráció megerősítés!</h1>
        <p class="pCenter marginBottom">
          Kérjük, erősítsd meg regisztrációdat az <b>e-mailen küldött link</b> segítségével.
        </p>
        <p class="pCenter marginBottom">
          Ezután fogsz tudni bejelentkezni.
        </p>
        <ion-button shape="round" expand="block" color="primary" class="marginBottom" @click="$router.push('/login')">Megerősítettem</ion-button>
        <ion-button expand="block" fill="transparent" color="primary" @click="$router.push('/app/udvozoljuk')">Vissza a főoldalra</ion-button>
      </ion-card-content>
    </ion-card>
    <div class="bottomSpacer"></div>

  </master-layout>
</template>

<script>
import {
  IonCard, IonCardContent, IonButton
} from '@ionic/vue'

export default {
  components: {
    IonCard, IonCardContent, IonButton
  }
}
</script>

<style scoped>
ion-button{
  margin: 10px 0;
}
.marginBottom{
  margin-bottom: 23px;
}
.illustration{
  display: block;
  width: 50%;
  margin: 40px auto 20px auto;
}
h1{
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 33px;
}
</style>
